import React from "react";

import Wrapper from "../components/Wrapper";
import ErrorImage from "../img/404.svg";

const MissingPage = () => {
  return (
    <Wrapper>
      <div className="text-center font-sans pt-8">
        <div className="mx-auto mt-12 flex justify-center">
          <img src={ErrorImage} alt="Not Found" />
        </div>
        <h1 className="font-bold mt-8 text-4xl text-en-gray-800">
          This page is no longer with us
        </h1>
      </div>
    </Wrapper>
  );
};

MissingPage.propTypes = {};

export default MissingPage;
